import { colors, fontWeights, spacings } from '@homelight/particle-tokens';
import { Typography } from '@mui/material';

type BureauCardProps = {
  name: string;
  phone: string;
  mail: string;
  website: string;
};
const BureauCard = ({ name, phone, mail, website }: BureauCardProps) => {
  return (
    <div style={{ marginBottom: spacings.spacingXs }}>
      <Typography
        fontWeight={fontWeights.fontWeightBold}
        color={colors.darkBlue}
      >
        {name}
      </Typography>
      <Typography
        display={'inline-block'}
        flexDirection={'row'}
        fontWeight={500}
        color={colors.darkBlue}
      >
        Phone: {phone}
      </Typography>
      <Typography
        display={'inline-block'}
        flexDirection={'row'}
        fontWeight={500}
        color={colors.darkBlue}
      >
        Mail: {mail}
      </Typography>
      <Typography
        display={'inline-block'}
        flexDirection={'row'}
        fontWeight={500}
        color={colors.darkBlue}
      >
        Website:{' '}
        <a
          href={`//${website}`}
          target="_blank"
          style={{ textDecoration: 'underline', color: colors.electricBlue }}
        >
          {website}
        </a>
      </Typography>
    </div>
  );
};

export default BureauCard;
