import { SizingProps, SpacingProps } from '@mui/system';

import ResponsiveDisplay from '../ResponsiveDisplay';
import { Tab, TabsActiveMarker, TabsHead, TabsWrapper } from './styles';

type SimpleTabsProps = {
  tabs: {
    value: string;
    label: string;
    mobileLabel?: string;
  }[];
  currentTab: string | string[];
  onChange: ({ tab }: { tab: string }) => void;
} & SpacingProps &
  SizingProps;

const SimpleTabs = ({
  tabs,
  currentTab,
  onChange = () => {},
  ...rest
}: SimpleTabsProps): React.ReactElement => {
  return (
    <TabsWrapper {...rest}>
      <TabsHead>
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            active={tab.value === currentTab}
            onClick={() => onChange({ tab: tab.value })}
            data-test={`tab-${tab.value}`}
          >
            <ResponsiveDisplay
              mobile={tab.mobileLabel || tab.label}
              desktop={tab.label}
            />
            <TabsActiveMarker
              key={`tab-marker-${tab.value}`}
              data-test={`tab-marker-${tab.value}-${
                tab.value === currentTab ? 'active' : 'inactive'
              }`}
            />
          </Tab>
        ))}
      </TabsHead>
    </TabsWrapper>
  );
};

export default SimpleTabs;
