import { useEffect } from 'react';

import { useRouter } from 'next/router';

import {
  EQUITY_APP_PAGES,
  PUBLIC_PAGES,
  TOKENIZED_PAGES,
} from '@hl-portals/constants';

import { GTM } from '@hl-portals/helpers';

import { useHubspot, useUserEvents } from '@hl-portals/hooks';

export function usePageTracking(source: 'agent_dashboard' | 'client_portal') {
  const router = useRouter();
  const { recordUserEvent } = useUserEvents();
  const { trackPageView: trackHubspotPageView } = useHubspot();
  const isPublicPage =
    PUBLIC_PAGES.includes(router.pathname) ||
    TOKENIZED_PAGES.includes(router.pathname) ||
    EQUITY_APP_PAGES.includes(router.pathname);

  useEffect(() => {
    const { ref } = router.query;

    if (ref) {
      recordUserEvent({
        source: 'agent_dashboard',
        event_action: ref as string,
      });
    }
  }, [router.query]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackHubspotPageView();

      if (!isPublicPage) {
        GTM.trackPageView(url);
        recordUserEvent({
          source,
          event_action: 'page_view',
          result: {
            url,
          },
        });
      }
    };

    handleRouteChange(router.asPath);

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    return undefined;
  }, [router.events, isPublicPage]);
}
