import { ReactElement, createContext, useContext, useState } from 'react';

import { useRouter } from 'next/router';

import { SELF_SERVICE_FLOW_PAGES } from '@hl-portals/constants';

import { useTransactionsMixin } from '../hooks/useTransactionsMixin';

interface IAppState {
  [key: string]: any;
}

interface UseAppContext {
  appState: IAppState;
  addAppState: (newState: IAppState) => void;
  removeAppState: (stateNames: string[]) => void;
}

const AppContext = createContext(null);

export const useAppContext = (): UseAppContext => useContext(AppContext);

export const AppContextProvider = ({ children }): ReactElement => {
  const [appState, setAppState] = useState<IAppState>({});
  const router = useRouter();

  const addAppState = (newState: IAppState) => {
    setAppState({
      ...appState,
      ...newState,
    });
  };

  const removeAppState = (stateNames: string[]) => {
    const newStateObj = { ...appState };
    stateNames.forEach((stateName) => {
      delete newStateObj[stateName];
    });
    setAppState(newStateObj);
  };

  useTransactionsMixin({
    appState,
    addAppState,
    enabled: !SELF_SERVICE_FLOW_PAGES.includes(router.pathname),
  });

  return (
    <AppContext.Provider
      value={{
        appState,
        addAppState,
        removeAppState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
