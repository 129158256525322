import { useQuery } from 'react-query';

import { AGENT_USER_SURVEY_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

const useAgentUserSurvey = ({ webId }: { webId: string }) =>
  useQuery({
    queryKey: `AGENT_USER_SURVEY_${webId}`,
    queryFn: () =>
      enhancedAxios<AgentUserSurvey>({
        url: AGENT_USER_SURVEY_PATH({ webId }),
        translateJsona: true,
      }),
    enabled: Boolean(webId),
  });

export default useAgentUserSurvey;
