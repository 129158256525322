import { Box } from '@mui/system';

import { useCallback, useEffect, useState } from 'react';

import Link from 'next/link';

import { Avatar } from '@hl-portals/ui';

import {
  useHandleClickOutside,
  useScreenSize,
  useToggleBodyScroll,
} from '@hl-portals/hooks';

import NavbarDropdownFooter from './NavbarDropdownFooter';
import NavbarDropdownHeader from './NavbarDropdownHeader';
import {
  DropdownButton,
  NavbarDropdownWrapper,
  NavbarItem,
  NavbarSection,
} from './styles';
import { INavbarDropdownProps } from './types';

const NavbarDropdownItem = ({ item, onClose }) => (
  <NavbarItem data-test="navbar-dropdown-item">
    <Link
      href={typeof item.url === 'function' ? item.url() : item.url ?? ''}
      passHref
    >
      <a
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          item.onClick && item.onClick();
          onClose();
        }}
        target={item.openInNewTab ? '_blank' : '_self'}
        title={item.text}
      >
        {item.text}
      </a>
    </Link>
  </NavbarItem>
);

const NavbarDropdown = ({
  sections = [[]],
  isElite,
  user,
  text,
}: INavbarDropdownProps): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { isDesktop } = useScreenSize();
  const { toggleScroll } = useToggleBodyScroll();

  const navRef = useHandleClickOutside<HTMLElement>({
    onClickOutside: () => setIsOpen(false),
  });

  const onToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      if (!isDesktop) {
        toggleScroll(false);
      }
    } else {
      toggleScroll(true);
    }
  }, [isOpen, isDesktop]);

  return (
    <>
      <DropdownButton
        isElite={isElite}
        role="button"
        tabIndex={0}
        onClick={onToggle}
        onKeyPress={onToggle}
        data-test="navbar-dropdown-button"
      >
        <Avatar
          type="normal"
          initials={user?.firstName?.[0]}
          display="flex"
          surface="blue"
        />
        <span>{text}</span>
      </DropdownButton>

      <NavbarDropdownWrapper ref={navRef} isOpen={isOpen}>
        <NavbarDropdownHeader
          user={user}
          onClose={onClose}
          isDesktop={isDesktop}
        />

        {!!sections.length &&
          sections.map((sectionItems, i) => {
            const sectionItemsMap = sectionItems.reduce(
              (obj, item) => {
                if (item.isDynamic) {
                  obj.dynamicItems.push(item);
                } else {
                  obj.staticItems.push(item);
                }
                return obj;
              },
              { staticItems: [], dynamicItems: [] }
            );

            return (
              <NavbarSection key={i}>
                {!!sectionItemsMap.dynamicItems.length && (
                  <Box
                    maxHeight="200px"
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                  >
                    {sectionItemsMap.dynamicItems.map((item) => (
                      <NavbarDropdownItem
                        key={item.id}
                        item={item}
                        onClose={onClose}
                      />
                    ))}
                  </Box>
                )}
                {sectionItemsMap.staticItems.map((item) => (
                  <NavbarDropdownItem
                    key={item.id}
                    item={item}
                    onClose={onClose}
                  />
                ))}
              </NavbarSection>
            );
          })}

        <NavbarDropdownFooter onLinkClick={onClose} />
      </NavbarDropdownWrapper>
    </>
  );
};

export default NavbarDropdown;
export * from './types';
