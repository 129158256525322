import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Button,
  ButtonProps,
  FadeIn,
  Paragraph,
  Spinner,
} from '@hl-portals/ui';

const Layout = ({
  title,
  description,
  buttonText = 'Submit',
  onNext,
  isLoading,
  buttonProps,
  children,
}: {
  title: string;
  description: string;
  buttonText?: string;
  buttonProps?: Partial<ButtonProps>;
  onNext: () => void;
  isLoading: boolean;
} & PropsWithChildren) => {
  const { handleSubmit } = useFormContext();

  return (
    <FadeIn
      flexDirection="column"
      alignItems="center"
      maxWidth="504px"
      animationDelay="0s"
    >
      <form onSubmit={handleSubmit(onNext)}>
        <FadeIn
          flexDirection="column"
          alignItems="center"
          animationDelay="0.1s"
        >
          <Paragraph
            fontSize={{ xs: '24px', md: '32px' }}
            variant="heading-2"
            mb="16px"
            textAlign={{ xs: 'left', sm: 'center' }}
            width="100%"
          >
            {title}
          </Paragraph>
          <Paragraph variant="text" mb="32px">
            {description}
          </Paragraph>
        </FadeIn>
        <FadeIn
          animationDelay="0.3s"
          flexDirection="column"
          alignItems="center"
        >
          {children}
        </FadeIn>
        <FadeIn>
          <Button
            mt="32px"
            width="100%"
            size="large"
            type="submit"
            onClick={onNext}
            data-test="mfa-submit"
            {...buttonProps}
            minHeight="48px"
          >
            {isLoading ? <Spinner /> : buttonText}
          </Button>
        </FadeIn>
      </form>
    </FadeIn>
  );
};

export default Layout;
