import dayjs from 'dayjs';

import { ReactText } from 'react';
import { toast } from 'react-toastify';

// TODO add toasts
export const showErrorToast = (docName: string): ReactText =>
  toast(
    <>
      Error trying to view{' '}
      <strong style={{ fontWeight: 600 }}>{docName}</strong>
    </>,
    { type: 'error' }
  );

export const getTimeString = (updatedAt: string): string => {
  const date = dayjs(updatedAt).format('MMM D');
  const time = dayjs(updatedAt).format('h:mm A');

  return `${date}, ${time}`;
};
