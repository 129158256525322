export const HOTJAR_IDS = {
  AGENT: {
    GENERAL: '2476769',
    LEAD_CAPTURE_PAGE_V2: '3637490',
  },
  CLIENT: {
    EXPRESS_APPROVAL: '3641852',
  },
  EQUITY_APP: '3743383',
};
