import { EQUITY_CALCULATOR_PUBLIC_PATH } from '@hl-portals/constants';

import { enhancedAxios, normalizeCurrency } from '@hl-portals/helpers';

export type CalculateEquityClientArgs = {
  name?: string;
  email?: string;
  propertyUUID: string;
  state: string;
  value: string;
  mortgage: string;
  source?: string;
  slug: string;
  localUUID: string;
};

export async function calculateClientEquity({
  name,
  email,
  propertyUUID,
  state,
  value,
  mortgage,
  source,
  slug,
  localUUID,
}: CalculateEquityClientArgs) {
  const res = await enhancedAxios({
    url: EQUITY_CALCULATOR_PUBLIC_PATH,
    params: {
      client_name: name,
      client_email: email,
      property_uuid: propertyUUID,
      property_state: state,
      client_valuation: value && normalizeCurrency(value),
      agent_valuation: value && normalizeCurrency(value),
      mortgage_balance: mortgage && normalizeCurrency(mortgage),
      agent_estimated_loan_payoff: mortgage && normalizeCurrency(mortgage),
      source,
      agent_slug: slug,
      uuid: localUUID,
    },
  });
  return res?.data?.attributes;
}
