/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/nextjs';

import { useEffect } from 'react';

import { Spinner } from '@hl-portals/ui';

import PasswordStepUpAuth from './factors/Password';
import { StepUpAuthModalProps, verificationAuth } from './helpers';

const StepUpAuth = ({ onDone, data }: StepUpAuthModalProps) => {
  useEffect(() => {
    if (!data.auth_complete) {
      verificationAuth().catch((err) => {
        captureException(err);
      });
    }
  }, [data.auth_complete]);

  return data.scope === 'restricted' ? (
    <PasswordStepUpAuth onDone={onDone} data={data} />
  ) : (
    <Spinner xl />
  );
};

export default StepUpAuth;
