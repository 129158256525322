export const CLOSING_SERVICES_MILESTONES = {
  order_opened: 'Order Opened',
  title_commitment_complete: 'Title Commitment Complete',
  clear_to_close: 'Clear to Close',
  closing_scheduled: 'Signing Scheduled',
  closing_complete: 'Closing Complete',
  review_your_closing: 'Review Your Closing',
  pending_cancellation: 'Pending Cancellation',
};

export const CASH_OFFER_STAGES = {
  new: 'New',
  connected: 'Connected',
  pitched: 'Pitched',
  agreement_signed: 'Agreement Signed',
  offer_accepted: 'Offer Accepted',
  in_escrow_purchase: 'In Escrow Purchase',
  closed_purchase: 'Closed Purchase',
  in_escrow_sell: 'In Escrow Sell',
  closed_sell: 'Closed Sell',
  closed_paid: 'Closed Paid',
  failed: 'Failed',
  nurture: 'Nurture',
  property_pending: 'Property Pending',
  property_submitted: 'Property Submitted',
  property_approved: 'Property Approved',
  property_cancelled: 'Property Cancelled',
  property_rejected: 'Property Rejected',
  offer_made: 'Offer Made',
  offer_rejected: 'Offer Rejected',
  offer_cancelled: 'Offer Cancelled',
};

export const CASH_OFFER_INACTIVE_STAGES = [
  'property_cancelled',
  'property_rejected',
  'offer_rejected',
  'offer_cancelled',
];

export const NEW_REFERRAL_LEAD_STAGES = [
  'new',
  'introduced',
  'intro_request',
  'auto_intro_match',
  'client_added_match',
  'quiz_match',
  'client_left_vm',
];
