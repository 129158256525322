import { useCallback, useEffect, useState } from 'react';

export const useToggleBodyScroll = () => {
  const [isOn, setIsOn] = useState(true);

  const toggleScroll = useCallback((value: boolean) => {
    setIsOn(value);
  }, []);

  useEffect(() => {
    if (isOn) {
      document.body.style.overflowY = 'auto';
    } else {
      document.body.style.overflowY = 'hidden';
    }
  }, [isOn]);

  return { isOn, toggleScroll };
};
