import { colors } from '@homelight/particle-tokens';

import React from 'react';

import { hooks } from '@hl-portals/libs/auth';

import { Box, BoxTypes, Icon } from '@hl-portals/ui';

const { useUser } = hooks;

export default function UserIcon({ ...props }: BoxTypes) {
  const user = useUser();

  return (
    <Box
      alignItems="center"
      backgroundColor={colors.electricBlue}
      borderRadius="50%"
      color={colors.white}
      height="32px"
      justifyContent="center"
      width="32px"
      {...props}
    >
      {user?.name ? user.name[0] : <Icon type="user" />}
    </Box>
  );
}
