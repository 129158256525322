import { useQuery } from 'react-query';

import { TRANSACTION_LEAD_PROGRESS_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

export interface TransactionProgressResponse {
  data: TransactionProgress;
  links: {
    first: string;
    last: string;
  };
  meta: {
    total_transactions: number;
  };
}

export const useTransactionProgress = ({
  leadId,
}: {
  leadId: string | string[];
}) =>
  useQuery({
    queryKey: `TRANSACTION_LEAD_PROGRESS_PATH_${leadId}`,
    queryFn: () =>
      enhancedAxios<TransactionProgressResponse>({
        url: TRANSACTION_LEAD_PROGRESS_PATH({ leadId: leadId.toString() }),
        params: {},
      }),
    enabled: Boolean(leadId),
  });
