import { Box, Paragraph, Spinner } from '@hl-portals/ui';

import { AutocompleteOption, getProduct, getSuggestedAddress } from './helpers';

const PropertyOption = ({
  option,
  searchedTerm,
}: {
  option: AutocompleteOption;
  searchedTerm: string;
}) => {
  const suggestedAddress = getSuggestedAddress(
    option.display_address,
    searchedTerm
  );

  const product = getProduct(option.lead);

  return (
    <Box flexDirection="column" width={1} py="12px" px={'20px'}>
      {option.type === 'loading' ? (
        <Spinner />
      ) : (
        <Box
          data-test={`instant_open_input_address-result`}
          flexDirection="column"
        >
          <Paragraph variant="text-small" color="darkBlue" mr="12px">
            {suggestedAddress}
          </Paragraph>
          {option.client_name && (
            <Paragraph
              variant="text-small"
              fontWeight="600"
              color="coolGray1"
              mr="12px"
            >
              {option.client_name}
              {product && ` - ${product}`}
            </Paragraph>
          )}
        </Box>
      )}
    </Box>
  );
};

export default PropertyOption;
