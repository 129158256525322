/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';

import { TRANSACTION_CLIENTS_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

interface UseTransactionContacts {
  data: TransactionPerson[];
  isLoading: boolean;
}

const useTransactionContacts = ({
  orderId,
  side,
}: {
  orderId: number | string;
  side: 'buyer' | 'seller';
}): UseTransactionContacts =>
  useQuery({
    queryKey: `TRANSACTION_CLIENTS_PATH_${orderId}`,
    queryFn: () =>
      enhancedAxios({
        url: TRANSACTION_CLIENTS_PATH({ orderId, side }),
        translateJsona: true,
      }),
    enabled: !!orderId,
  });

export default useTransactionContacts;
