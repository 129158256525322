/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/nextjs';

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Input, InputGroup } from '@hl-portals/ui';

import Layout from '../../Layout';
import {
  StepUpAuthModalProps,
  submitVerification,
  useRequestVerification,
  verificationAuth,
} from '../../helpers';

const Verification = ({ onDone, data }: StepUpAuthModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uuid, setUuid] = useState();
  const [authenticationDeviceId, setAuthenticationDeviceId] = useState('');
  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useFormContext();

  const { data: verificationResponse, refetch } = useRequestVerification(
    authenticationDeviceId
  );

  const FORM_STATE = watch();

  const onNext = () => {
    setIsLoading(true);
    submitVerification({
      uuid,
      password: FORM_STATE.password,
    })
      .then((result) => {
        if (result?.data?.attributes?.status === 'accepted') {
          onDone(true);
        } else {
          verificationAuth().then(refetch).catch(captureException);
          setError('password', {
            type: 'onBlur',
            message: 'Incorrect password',
          });
        }
      })
      .catch(() => {
        verificationAuth().then(refetch).catch(captureException);
        toast('Error submitting password, please refresh and try again', {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (verificationResponse?.data?.id) {
      clearErrors('authenticationDevice');
      setUuid(verificationResponse.data.id);
    }
  }, [verificationResponse]);

  useEffect(() => {
    setError('authenticationDevice', {
      type: 'onBlur',
      message: `Authentication device id not found`,
    });

    const authenticationDevice = data.authentication_devices?.data.length
      ? data.authentication_devices?.data[0]
      : { id: '' };

    setAuthenticationDeviceId(authenticationDevice.id);
  }, [data]);

  return (
    <Layout
      title="Enter your password"
      description="You'll be signed in to your account after you enter your password."
      onNext={onNext}
      isLoading={isLoading}
      buttonText="Submit"
      buttonProps={{
        disabled: !isValid || isLoading,
      }}
    >
      <InputGroup
        label="Password"
        width="100%"
        mb="32px"
        error={errors.password?.message as string}
      >
        <Input
          {...register('password', {
            required: true,
          })}
          onBlur={() => errors.password && clearErrors('password')}
          isError={!!errors.password?.message}
          type="password"
          data-test="mfa-password"
        />
      </InputGroup>
    </Layout>
  );
};

export default Verification;
