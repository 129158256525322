import { formatDate, sanitizeString } from '@hl-portals/helpers';

import { Avatar } from '../Avatar';
import { Box } from '../Box';
import ResponsiveDisplay from '../ResponsiveDisplay';
import { Paragraph } from '../Typography';
import { MessageThreadWrapper } from './styles';

interface MessageThreadProps {
  author: string;
  avatarUrl?: string;
  lastMessage: string;
  lastMessageTime: string;
  read?: boolean;
}

const MessageThread = ({
  author,
  avatarUrl,
  lastMessage,
  lastMessageTime,
  read,
}: MessageThreadProps): React.ReactElement => (
  <MessageThreadWrapper data-test="message-thread">
    <Avatar
      initials={author[0]}
      src={avatarUrl}
      type="normal"
      size="big"
      data-test="message-thread__avatar"
    />
    <Box
      flexDirection={{ xs: 'column', md: 'row' }}
      flexWrap="nowrap"
      width={{ xs: 'calc(100% - 40px)', md: 1 }}
      ml="16px"
    >
      <Box width={1} flexDirection="column" mb={{ xs: '12px', md: 0 }}>
        <Box justifyContent="space-between">
          <Paragraph
            variant="text-xsmall-bold"
            color="darkBlue"
            mb="4px"
            as="div"
            data-test="message-thread__author"
          >
            {author}
          </Paragraph>

          <ResponsiveDisplay
            desktop={
              <Paragraph
                variant="text-xsmall"
                color="coolGray2"
                as="time"
                ml={{ md: 'auto' }}
                data-test="message-thread__time"
              >
                {formatDate({
                  dateString: lastMessageTime,
                  dateFormat: 'ddd, MMM D, YYYY, h:mm A',
                })}
              </Paragraph>
            }
          />
        </Box>

        <Paragraph
          variant={read ? 'text' : 'text-bold'}
          color="coolGray2"
          truncate
          maxWidth={{
            xs: '90%',
            md: '300px',
          }}
          data-test="message-thread__message"
        >
          {sanitizeString(lastMessage)}
        </Paragraph>
      </Box>

      <ResponsiveDisplay
        mobile={
          <Paragraph
            variant="text-xsmall"
            color="coolGray2"
            as="time"
            ml={{ md: 'auto' }}
            data-test="message-thread__time"
          >
            {formatDate({
              dateString: lastMessageTime,
              dateFormat: 'ddd, MMM D, YYYY, h:mm A',
            })}
          </Paragraph>
        }
      />
    </Box>
  </MessageThreadWrapper>
);

export default MessageThread;
