import { useLayoutEffect, useRef } from 'react';

import { BoxTypes } from '../Box';
import ScrollableWrapper from './styles';

interface ScrollableProps {
  position?: 'top' | 'bottom' | string;
  children: React.ReactNode;
}

const Scrollable = (props: ScrollableProps & BoxTypes): React.ReactElement => {
  const { position = 'top', children } = props;
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (position === 'bottom' && ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [position, children]);

  return (
    <ScrollableWrapper
      ref={ref}
      padding="20px"
      flexGrow={1}
      data-test="scrollable"
      {...props}
    >
      {children}
    </ScrollableWrapper>
  );
};

export default Scrollable;
