import { useEffect, useState } from 'react';

import { MapboxStaticMapProps } from '@hl-portals/ui';

import { config } from '@hl-portals/helpers';

import { useMapboxPlaces } from '@hl-portals/hooks';

const mapboxBaseUrl = 'https://api.mapbox.com';

// TODO - needs update?
const mapboxUserName = 'mapbox';
const mapboxStyleId = 'streets-v11';

type UseMapboxStaticMap = {
  address: string;
  searchType?: string;
} & MapboxStaticMapProps;

const useMapboxStaticMap = ({
  address,
  width = 300,
  height = 300,
  zoom = 15,
  bearing = 0,
  pitch = 0,
  searchType = 'address'
}: UseMapboxStaticMap) => {
  const places = useMapboxPlaces(address, { enabled: true }, searchType);

  const [mapboxSrc, setMapboxSrc] = useState('');

  useEffect(() => {
    if (!places.data) return;
    const { lat, lon } = places.data[0] ?? {};

    if (!lat || !lon) return;

    const url = `${mapboxBaseUrl}/styles/v1/${mapboxUserName}/${mapboxStyleId}/static/${lon},${lat},${zoom},${bearing},${pitch}/${width}x${height}?access_token=${config.mapboxAccessToken}&attribution=false`;

    setMapboxSrc(url);
  }, [places.data, width, height, zoom, bearing, pitch]);

  return mapboxSrc;
};

export default useMapboxStaticMap;
