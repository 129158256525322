import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import CloseIcon from '../assets/CloseIcon';
import { ModalStatus } from '../modal.context';
import { CloseButton, ModalStage } from '../styles';
import BackdropComponent from './backdrop.component';

type TModalPortal = {
  closeModalHandler: () => void;
  animationClassName: string;
  currentStatus: ModalStatus;
  onBackdropAnimationEnd?: () => void;
  onStageAnimationEnd?: () => void;
  wrapperClassName?: string;
  hideCloseIcon?: boolean;
  enableBackDropClick?: boolean;
  enableCloseOnEsc?: boolean;
  enableClose?: boolean;
  modalDataTest?: string;
  asDrawer?: boolean;
};

const ModalPortal = ({
  animationClassName,
  onBackdropAnimationEnd,
  onStageAnimationEnd,
  wrapperClassName = '',
  closeModalHandler,
  hideCloseIcon,
  children,
  enableBackDropClick,
  enableCloseOnEsc,
  enableClose,
  modalDataTest,
  currentStatus,
  asDrawer,
}: React.PropsWithChildren<TModalPortal>) => {
  const [modalRootNode, setModalRootNode] = useState<HTMLElement>();

  useEffect(() => {
    const modalRoot = document.createElement('div');
    modalRoot.style.zIndex = '31';
    modalRoot.style.position = 'fixed';
    setModalRootNode(modalRoot);
    const body = document.querySelector('body');
    body.appendChild(modalRoot);

    return () => modalRoot.remove();
  }, []);

  if (!modalRootNode) {
    return <></>;
  }

  return ReactDOM.createPortal(
    <BackdropComponent
      currentStatus={currentStatus}
      className={animationClassName}
      closeModalHandler={closeModalHandler}
      onAnimationEnd={onBackdropAnimationEnd}
      enableBackDropClick={enableBackDropClick}
      enableCloseOnEsc={enableCloseOnEsc}
      enableClose={enableClose}
    >
      <ModalStage
        className={wrapperClassName}
        id="modal"
        onClick={(e) => e.stopPropagation()}
        onAnimationEnd={onStageAnimationEnd}
        asDrawer={asDrawer}
        data-test={modalDataTest}
      >
        {!hideCloseIcon && (
          <CloseButton
            role="button"
            tabIndex={0}
            onClick={closeModalHandler}
            onKeyPress={closeModalHandler}
            data-test="modal__close-button"
          >
            <CloseIcon />
          </CloseButton>
        )}
        {children}
      </ModalStage>
    </BackdropComponent>,
    modalRootNode
  );
};

export default ModalPortal;
