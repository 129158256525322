import { useMutation } from 'react-query';

import { TASK_COMPLETE_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

export const useTaskComplete = () =>
  useMutation(({ taskId }: { taskId: string }) =>
    enhancedAxios({
      method: 'PUT',
      url: TASK_COMPLETE_PATH({ taskId }),
    })
  );
