import { useQuery } from 'react-query';

import { CLIENT_TRANSACTIONS_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

export const useTransactions = ({
  userId,
  enabled = true,
}: {
  userId: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: `CLIENT_TRANSACTIONS_PATH_${userId}`,
    queryFn: () =>
      enhancedAxios<TransactionListResponse>({
        url: CLIENT_TRANSACTIONS_PATH,
        params: {},
      }),
    enabled: Boolean(userId) && enabled,
  });
