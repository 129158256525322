import { useQuery } from 'react-query';

import { TRANSACTIONS_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { useDebounce } from '@hl-portals/hooks';

interface OrderSearch {
  orders?: OrderResponse;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

const getOrders = ({
  debouncedTerm,
  userTypes,
  secondarySearch,
}: {
  debouncedTerm: string;
  userTypes: string;
  secondarySearch?: {
    emails?: string;
  };
}) =>
  enhancedAxios<OrderResponse>({
    url: TRANSACTIONS_PATH({
      page: '1',
      search: debouncedTerm,
      service: userTypes,
      similarEmails: secondarySearch && secondarySearch['emails'],
      sort: '-created_at',
      teamMemberId: 'All',
    }),
    translateJsona: true,
  });

const useOrderSearch = (
  term: string,
  secondarySearch?: { emails?: string }
): OrderSearch => {
  const debouncedTerm = useDebounce(term, 500);
  const userTypes =
    'buyer,cc_trade_in,cc_hl_simple_sale,cc_cash_offer,escrow,seller';

  const { data, isFetching, isSuccess, isError } = useQuery({
    queryKey: `ORDER_SEARCH_RESPONSE_${debouncedTerm}`,
    queryFn: () => getOrders({ debouncedTerm, userTypes, secondarySearch }),
    enabled: term.length > 2 && debouncedTerm.length > 0,
  });

  return {
    orders: data,
    isSuccess,
    isError,
    isLoading: isFetching,
  };
};

export default useOrderSearch;
