import Lottie from 'react-lottie-player';

import { FadeIn } from '../Animations';
import { Box } from '../Box';
import { ProgressBar } from '../ProgressBar';
import { Paragraph } from '../Typography';
import lottieJson from './lottie-photo-loading.json';

type LoadingProps = {
  title: string;
  percent?: string | number;
};

export const Loading = ({ title, percent }: LoadingProps) => {
  return (
    <FadeIn
      width="100%"
      height="calc(100vh - 107px)"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="120px">
        <Lottie loop animationData={lottieJson} play />
      </Box>
      {typeof percent !== 'undefined' ? (
        <ProgressBar width="225px" progress={percent} />
      ) : null}
      <Paragraph variant="heading-4" mb="12px" mt="20px">
        {title}
      </Paragraph>
      <Paragraph color="gray700">Please don't close the window.</Paragraph>
    </FadeIn>
  );
};
