export default [
  {
    type: 'equifax',
    name: 'Equifax',
    phone: '1-888-298-0045',
    mail: 'Equifax Information Services LLC, P.O. Box 105788 Atlanta, GA 30348-5788',
    website: 'equifax.com/personal/credit-report-services/',
  },
  {
    type: 'experian',
    name: 'Experian',
    phone: '1-888-397-3742',
    mail: 'Experian Security Freeze, P.O. Box 9554, Allen, TX 75013',
    website: 'experian.com/freeze/center.html',
  },
  {
    type: 'transunion',
    name: 'TransUnion',
    phone: '1-888-909-8872',
    mail: 'TransUnion, P.O. Box 160, Woodlyn, PA 19094',
    website: 'transunion.com/credit-freeze',
  },
];
