/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Autocomplete } from '@hl-portals/ui';

import { useOrderSearch, usePropertyAutocomplete } from '@hl-portals/hooks';

import PropertyOption from './PropertyOption';
import { AutocompleteOption, compileOptions } from './helpers';

interface PropertyAutocompleteProps {
  onChange: ({
    term,
    setTerm,
  }: {
    term: string;
    setTerm: Dispatch<SetStateAction<string>>;
  }) => void;
  onEnter: ({
    option,
  }: {
    option: AutocompleteOption;
    setTerm: Dispatch<SetStateAction<string>>;
  }) => void;
  transformOrders?: (orders: OrderJsonaResponse[]) => OrderJsonaResponse[];
  value: string;
  searchOrders?: boolean;
  autofocus?: boolean;
  onBlur?: () => void;
  isError?: boolean;
}

const PropertyAutocomplete = ({
  onChange,
  onEnter,
  value,
  transformOrders = (orders) => orders,
  searchOrders = true,
  autofocus = true,
  ...props
}: PropertyAutocompleteProps) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<AutocompleteOption[]>([]);

  const ordersResponse = useOrderSearch(searchOrders ? search : '');
  const propertiesResponse = usePropertyAutocomplete(search);

  useEffect(() => {
    if ((searchOrders && !ordersResponse.isSuccess) || !propertiesResponse.data)
      return;

    const newOptions = compileOptions(
      transformOrders(ordersResponse.orders?.data || []),
      propertiesResponse.data
    );

    // only update if options have changed
    if (JSON.stringify(newOptions) !== JSON.stringify(options))
      setOptions(newOptions);
  }, [ordersResponse, propertiesResponse]);

  const onAutocompleteChange = ({
    term,
    setTerm,
  }: {
    term: string;
    setTerm: Dispatch<SetStateAction<string>>;
  }) => {
    setTerm(term);
    setSearch(term);
    onChange({ term, setTerm });
  };

  return (
    <Autocomplete<AutocompleteOption>
      autofocus={autofocus}
      placeholder="Enter property address"
      onChange={onAutocompleteChange}
      options={
        searchOrders && ordersResponse.isLoading
          ? [
              {
                type: 'loading',
                display_address: '',
                disabled: true,
              },
            ]
          : options
      }
      onEnter={onEnter}
      separator={() => <></>}
      tabProtection={false}
      value={value}
      {...props}
    >
      {({ option }) => <PropertyOption option={option} searchedTerm={search} />}
    </Autocomplete>
  );
};

export default PropertyAutocomplete;
