/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/nextjs';

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Input, InputGroup } from '@hl-portals/ui';

import Layout from '../../Layout';
import {
  StepUpAuthModalProps,
  submitRegistration,
  useRequestRegistration,
  verificationAuth,
} from '../../helpers';

const Registration = ({ onDone, data }: StepUpAuthModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uuid, setUuid] = useState();
  const [authenticationFactorId, setAuthenticationFactorId] = useState('');
  const {
    data: reqistrationResponse,
    refetch,
    error,
  } = useRequestRegistration(authenticationFactorId);

  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useFormContext();

  const FORM_STATE = watch();

  const onNext = () => {
    setIsLoading(true);

    submitRegistration({
      uuid,
      password: FORM_STATE.password,
      password_confirmation: FORM_STATE.confirmPassword,
    })
      .then((result) => {
        if (result?.data?.attributes?.status === 'accepted') {
          onDone(true);
        } else {
          verificationAuth().then(refetch).catch(captureException);
          setError('password', {
            type: 'onBlur',
            message: 'Incorrect password',
          });
        }
      })
      .catch(() => {
        verificationAuth().then(refetch).catch(captureException);
        toast('Error creating password, please refresh and try again', {
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (reqistrationResponse?.data?.id) {
      clearErrors('authenticationFactor');
      setUuid(reqistrationResponse.data.id);
    } else if (error) {
      refetch();
    }
  }, [reqistrationResponse, error]);

  useEffect(() => {
    setError('authenticationFactor', {
      type: 'onBlur',
      message: `Authentication factor id not found`,
    });

    setAuthenticationFactorId(
      data.authentication_factors?.data?.find(
        (factor) => factor.attributes.name === 'password_factor'
      )?.id
    );
  }, [data]);

  return (
    <Layout
      title="Create your password"
      description="You'll be signed in to your account after you create your password."
      onNext={onNext}
      buttonText="Save"
      isLoading={isLoading}
      buttonProps={{
        disabled: !isValid || isLoading,
      }}
    >
      <InputGroup
        label="Password"
        width="100%"
        mb="32px"
        error={errors.password?.message as string}
      >
        <Input
          {...register('password', {
            required: true,
            validate: (value) =>
              value.length >= 8 || 'Please enter a valid password.',
          })}
          isError={!!errors.password?.message}
          type="password"
          placeholder="Min. 8 characters"
          data-test="mfa-password"
        />
      </InputGroup>
      <InputGroup
        label="Confirm Password"
        width="100%"
        error={errors.confirmPassword?.message as string}
      >
        <Input
          {...register('confirmPassword', {
            required: true,
            validate: (value) =>
              value === FORM_STATE.password || 'Passwords must match.',
          })}
          isError={!!errors.confirmPassword?.message}
          type="password"
          data-test="mfa-confirm-password"
        />
      </InputGroup>
    </Layout>
  );
};

export default Registration;
