import { useQuery } from 'react-query';

import { MESSAGES_LIST_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

interface UseMessagesListParams {
  page: string | string[];
}

export interface UseMessagesListResponse {
  data: Array<MessageType>;
  meta: {
    total_messages: number;
  };
}

export const useMessagesList = ({ page }: UseMessagesListParams) =>
  useQuery({
    queryKey: `MESSAGES_LIST_PATH_${page}`,
    queryFn: () =>
      enhancedAxios<UseMessagesListResponse>({
        url: MESSAGES_LIST_PATH({ page: page?.toString() }),
        translateJsona: true,
      }),
    enabled: Boolean(page),
  });
