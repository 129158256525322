import { useEffect, useRef, useState } from 'react';
import { DrawerStyles } from './styles';
import { SizeState } from './types';

const DrawerComponent = ({
  elementHeight,
  sizeState,
  setSizeState,
  children,
}: React.PropsWithChildren<{
  elementHeight: number;
  sizeState: SizeState;
  setSizeState: React.Dispatch<React.SetStateAction<SizeState>>;
}>) => (
  <DrawerStyles
    elementHeight={elementHeight}
    sizeState={sizeState}
    onTransitionEnd={() => {
      setSizeState((state) => {
        if (state === 'COLLAPSING') {
          return 'COLLAPSED';
        }

        if (state === 'EXPANDING') {
          return 'EXPANDED';
        }

        return state;
      });
    }}
  >
    {children}
  </DrawerStyles>
);

const FROM_TO_STATES: {
  [key in SizeState]: SizeState;
} = {
  COLLAPSED: 'EXPANDING',
  EXPANDING: 'COLLAPSING',
  EXPANDED: 'BEFORE_COLLAPSING',
  BEFORE_COLLAPSING: 'EXPANDING',
  COLLAPSING: 'EXPANDING',
};

const useDrawer = (
  component: (
    ref: React.MutableRefObject<any>,
    toggleDrawer: () => void,
    isDrawerOpen: boolean
  ) => React.ComponentPropsWithRef<React.ElementType>,
  config?: {
    shouldStartOpen: boolean;
  }
): {
  toggleDrawer: () => void;
  isDrawerOpen: boolean;
  Drawer: JSX.Element;
} => {
  const ref = useRef<HTMLElement>(null);
  const [sizeState, setSizeState] = useState<SizeState>(
    config?.shouldStartOpen ? 'EXPANDED' : 'COLLAPSED'
  );
  const [elementHeight, setElementHeight] = useState<number>(0);

  useEffect(() => {
    if (sizeState === 'BEFORE_COLLAPSING') {
      setSizeState('COLLAPSING');
    }
  }, [sizeState]);

  const toggleDrawer = () => {
    setElementHeight(ref.current?.offsetHeight);
    setSizeState(FROM_TO_STATES[sizeState]);
  };

  const isDrawerOpen = sizeState === 'EXPANDED' || sizeState === 'EXPANDING';

  return {
    toggleDrawer,
    isDrawerOpen,
    Drawer: (
      <DrawerComponent
        elementHeight={elementHeight}
        setSizeState={setSizeState}
        sizeState={sizeState}
      >
        {component(ref, toggleDrawer, isDrawerOpen)}
      </DrawerComponent>
    ),
  };
};

export default useDrawer;
