/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { hooks } from '@hl-portals/libs/auth';

import { SUPPORTED_CLIENT_PORTAL_USER_TYPES } from '@hl-portals/constants';

import { useTransaction } from './useTransaction';
import { useTransactions } from './useTransactions';

const { useUser } = hooks;

export const useTransactionsMixin = ({
  appState,
  addAppState,
  enabled = true,
}) => {
  const user = useUser();
  const router = useRouter();

  const { data } = useTransactions({ userId: user?.id, enabled });
  const { data: transaction } = useTransaction({
    orderId: router.query.orderId,
    side: appState.order?.attributes?.transaction_side,
    enabled,
  });

  useEffect(() => {
    if (data?.data) {
      const filteredTxns = data.data.filter((obj) =>
        SUPPORTED_CLIENT_PORTAL_USER_TYPES.includes(
          obj?.attributes?.transaction_type
        )
      );
      addAppState({ transactions: filteredTxns });
    }
  }, [data]);

  useEffect(() => {
    addAppState({ transaction });
  }, [transaction]);

  useEffect(() => {
    if (
      router.query.orderId &&
      appState.transactions?.length &&
      !appState.order
    ) {
      const order = appState.transactions.find(
        (t) => t.attributes.order_id == router.query.orderId
      );

      if (order) {
        addAppState({
          order,
        });
      } else {
        router.push('/');
      }
    }
  }, [router.query, appState]);
};
