import React, { useCallback } from 'react';

import Link from 'next/link';

import { hooks } from '@hl-portals/libs/auth';

import { useUserEvents } from '@hl-portals/hooks';

import { Paragraph } from '../Typography';
import { NavbarFooterWrapper, NavbarItem } from './styles';
import { INavbarDropdownFooterProps } from './types';

const NavbarDropdownFooter = ({
  onLinkClick,
}: INavbarDropdownFooterProps): React.ReactElement => {
  const { recordUserEvent } = useUserEvents();
  const user = hooks.useUser();

  const onSignOut = useCallback(() => {
    if (user) {
      recordUserEvent({
        source: 'client_portal',
        event_action: 'user_logout',
        result: {
          lead_id: user.id as unknown as number,
        },
      });
    }
    onLinkClick();
  }, [onLinkClick, user]);

  return (
    <NavbarFooterWrapper>
      <NavbarItem data-test="navbar-dropdown-item">
        <Link href="/logout" passHref>
          <a onClick={onSignOut}>
            <Paragraph variant="text-small-semi-bold" color="aaBlue">
              Sign Out
            </Paragraph>
          </a>
        </Link>
      </NavbarItem>
    </NavbarFooterWrapper>
  );
};

export default NavbarDropdownFooter;
