import { useFlags } from 'flagsmith/react';
import { ClientEvent } from 'packages/hooks/src/useUserEvents/client-event-types';

import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { hooks } from '@hl-portals/libs/auth';

import { ImpersonationHeader, Navbar, Spinner } from '@hl-portals/ui';

import { config } from '@hl-portals/helpers';

import { useUserEvents } from '@hl-portals/hooks';

import { useAppContext } from '../../contexts/AppContext';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import UserIcon from '../UserIcon';

const { useUser } = hooks;

const defaultItems = [
  {
    key: 'spinner',
    display: <Spinner xl />,
    sections: [],
  },
];

const transactionTypeLabel = {
  cc_trade_in: 'Buy Before You Sell',
  cc_cash_offer: 'Cash Offer',
  cc_hl_simple_sale: 'Simple Sale',
  escrow: 'Closing Services',
};

const ClientNavbar = (): React.ReactElement => {
  const [navbarItems, setNavbarItems] = useState(defaultItems);

  const {
    'client-navbar-hlcs': { enabled: isHLCSNavbarEnabled },
  } = useFlags(['client-navbar-hlcs']);

  const user = useUser();
  const router = useRouter();
  const { data: currentUser } = useCurrentUser();
  const { recordUserEvent } = useUserEvents();
  const { appState, addAppState } = useAppContext();

  const getNavbarDropdownSections = (data: any) => {
    if (data) {
      const savedSearches = `${config.homelightHomes}/saved-property-searches`;
      const savedHomes = `${config.homelightHomes}/favorites`;

      const transactionLinks =
        data
          .filter((transaction: any) => {
            const type = transaction?.attributes?.transaction_type;

            if (isHLCSNavbarEnabled) {
              return type === 'cc_trade_in' || type === 'escrow';
            }
            return type === 'cc_trade_in';
          })
          .map((transaction: any) => {
            return {
              text: `${
                transactionTypeLabel[
                  transaction?.attributes?.transaction_type
                ] ?? ''
              }: ${transaction.attributes.address}`,
              url: `/transaction/${transaction.attributes.order_id}/dashboard`,
              onClick: () => {
                addAppState({ order: transaction });
              },
              id: transaction.id,
              type: 'link',
              isDynamic: true,
            };
          }) ?? [];

      const currentTransaction = data.find((transaction: any) => {
        const transactionOrderId = transaction.id.split('-')[1];
        return transactionOrderId === router.query.orderId;
      });

      const leadId = currentTransaction?.relationships?.primary_lead?.data?.id;
      const event = {
        source: 'client_portal',
        result: {
          leadId,
          clicked_at: new Date().toISOString(),
        },
      };

      return [
        [
          ...transactionLinks,
          { id: 'messages', text: 'Messages', url: '/messages', type: 'link' },
        ],
      ];
    }
  };

  useEffect(() => {
    if (user && appState.transactions) {
      setNavbarItems([
        {
          key: 'account',
          display: <UserIcon />,
          sections: getNavbarDropdownSections(appState.transactions),
        },
      ]);
    }
  }, [user, appState]);

  const nameSplitted = user?.name?.split(' ');

  const firstName = currentUser?.impersonated
    ? currentUser?.first_name
    : nameSplitted?.[0];
  const lastName = currentUser?.impersonated
    ? currentUser?.last_name
    : nameSplitted?.[nameSplitted.length - 1];
  const email = currentUser?.impersonated ? currentUser?.email : user?.email;
  const fullName = currentUser?.impersonated
    ? `${firstName} ${lastName}`
    : user?.name;

  return (
    <>
      {currentUser?.impersonated && <ImpersonationHeader name={fullName} />}
      <Navbar
        logoUrl="/homeLight-logo-horizontal.svg"
        items={navbarItems}
        user={{
          firstName: firstName,
          lastName: lastName,
          email: email,
        }}
      />
    </>
  );
};

export default ClientNavbar;
