import { zodResolver } from '@hookform/resolvers/zod';
import { ZodType } from 'zod';

import { useEffect } from 'react';
import { FieldValues, UseFormProps, useForm as useRHF } from 'react-hook-form';

type Props<T extends FieldValues = FieldValues> = UseFormProps<T> & {
  schema: ZodType;
};

export function useForm<T extends FieldValues = FieldValues>({
  schema,
  defaultValues,
  ...props
}: Props<T>) {
  const methods = useRHF<T>({
    defaultValues,
    resolver: schema && zodResolver(schema),
    reValidateMode: 'onChange',
    ...props,
  });

  useEffect(() => {
    methods.reset(defaultValues as any);
  }, [defaultValues, methods.reset]);

  return methods;
}
