/* eslint-disable react-hooks/exhaustive-deps */
import { createGlobalStyle } from 'styled-components';

import { FormProvider, useForm } from 'react-hook-form';

import { Box, Navbar } from '@hl-portals/ui';

import { StepUpAuthModalProps } from '../../helpers';
import Registration from './Registration';
import Verification from './Verification';

const SecureModalWrapperStyles = createGlobalStyle`
  .secureRequest-modal__wrapper {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;

    nav {
      box-shadow: none;
    }
  }

  div[role="button"] {
    display: none;
  }
`;

const PasswordStepUpAuth = ({ onDone, data }: StepUpAuthModalProps) => {
  const onComplete = (isSuccess) => {
    if (isSuccess) {
      onDone();
    }
  };

  const methods = useForm({
    mode: 'onChange',
  });

  return (
    <>
      <SecureModalWrapperStyles />
      <Navbar
        user={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        items={[]}
        logoUrl="/homeLight-logo-horizontal.svg"
      />
      <Box
        padding={{ xs: '24px 20px', sm: '16px' }}
        justifyContent="center"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        width="100%"
        height="calc(100% - 72px)"
      >
        <FormProvider {...methods}>
          {data.flow === 'verification' ? (
            <Verification onDone={onComplete} data={data} />
          ) : (
            <Registration onDone={onComplete} data={data} />
          )}
        </FormProvider>
      </Box>
    </>
  );
};

export default PasswordStepUpAuth;
