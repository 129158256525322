import { useFlags } from 'flagsmith/react';

import { PropsWithChildren, createContext, useContext } from 'react';

// =========================================================================
// Context

type ABTestContextType = {
  value: string | number | boolean | null | undefined;
  enabled: boolean;
};

const DEFAULT_STATE = { value: '', enabled: false };

const ABTestContext = createContext<ABTestContextType>(DEFAULT_STATE);

type ABTestRootProps = {
  flag: string;
};

const Root = ({ children, flag }: PropsWithChildren<ABTestRootProps>) => {
  const { [`${flag}`]: f = DEFAULT_STATE } = useFlags([`${flag}`]);

  return (
    <ABTestContext.Provider value={{ value: f.value, enabled: f.enabled }}>
      {children}
    </ABTestContext.Provider>
  );
};

export const useABTest = () => {
  const ctx = useContext(ABTestContext);
  if (!ctx) {
    throw new Error('Missing ABTest.Root');
  }
  return ctx;
};

// =========================================================================
// Subject - Represents each variation of the flag

type ABTestSubjectProps = {
  name: string;
};

const Subject = ({ children, name }: PropsWithChildren<ABTestSubjectProps>) => {
  const { value, enabled } = useABTest();

  if (enabled && value === name) return <>{children}</>;
  return <></>;
};

// =========================================================================
// Fallback - Rendered if the flag is off or without a value

const Fallback = ({ children }: PropsWithChildren) => {
  const { value, enabled } = useABTest();

  if (!enabled || !value) return <>{children}</>;
  return <></>;
};

// =========================================================================
// ABTest

export const ABTest = {
  Root,
  Subject,
  Fallback,
};
