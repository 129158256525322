let throttled;
let count = 0;

const throttle = (cb, delay = 250, times = 0) => {
  if (throttled || (times && count >= times)) return;

  throttled = true;

  setTimeout(() => {
    cb();
    count++;
    throttled = false;
  }, delay);
};

export default throttle;
