import * as Sentry from '@sentry/nextjs';

import { UseQueryOptions, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import {
  PROPERTY_ELIGIBILITY_CHECK,
  PROPERTY_ELIGIBILITY_CHECKS_LABELS,
  PROPERTY_ELIGIBILITY_CHECK_PUBLIC,
  PropertyEligibilityChecks,
} from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { useModal } from '@hl-portals/hooks';

import PropertyEligibilityModal from './PropertyEligibilityModal';

type PropertyEligibilityChecksResponse = {
  eligibility_checks: PropertyEligibilityChecks;
};

export const USE_PROPERTY_ELIGIBILITY_CHECKS_QUERY_KEY =
  'USE_PROPERTY_ELIGIBILITY_CHECKS_QUERY_KEY';

type PropertyEligibilityOptions = UseQueryOptions<string[]>;

type UsePropertyEligibilityChecksOptions = Omit<
  PropertyEligibilityOptions,
  'onSuccess'
> & {
  onSuccess: PropertyEligibilityOptions['onSuccess'];
};

export const usePropertyEligibilityCheck = (
  uuid: string,
  state: string,
  options: UsePropertyEligibilityChecksOptions & {
    isPublic?: boolean;
  }
) => {
  const { openModal, closeModal } = useModal();

  return useQuery<string[]>(
    [USE_PROPERTY_ELIGIBILITY_CHECKS_QUERY_KEY, uuid, state],
    async ({ signal }) => {
      const eligibilityURL = PROPERTY_ELIGIBILITY_CHECK(uuid, state);
      const eligibilityPublicURL = PROPERTY_ELIGIBILITY_CHECK_PUBLIC(
        uuid,
        state
      );

      const { eligibility_checks } =
        await enhancedAxios<PropertyEligibilityChecksResponse>({
          url: options?.isPublic ? eligibilityPublicURL : eligibilityURL,
          translateJsona: true,
          signal,
        });

      return (
        Object.keys(eligibility_checks)
          // @ts-ignore
          .filter((k) => !eligibility_checks[k])
          // @ts-ignore
          .map((issue) => PROPERTY_ELIGIBILITY_CHECKS_LABELS[issue])
      );
    },
    {
      ...options,
      onSuccess: (issues) => {
        if (issues.length) {
          openModal(
            <PropertyEligibilityModal
              items={issues}
              onContinue={() =>
                options?.onSuccess ? options.onSuccess(issues) : null
              }
              onCancel={closeModal}
            />
          );
          return;
        }
        if (options?.onSuccess) {
          options.onSuccess(issues);
        }
      },
      onError: (error) => {
        toast('There was an error', { type: 'error' });
        Sentry.captureException(error);

        if (options?.onError) {
          options.onError(error);
        }
      },
    }
  );
};
