/* eslint-disable @typescript-eslint/no-throw-literal */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { WarningDialog, WarningDialogProps } from '@hl-portals/ui';

import { useModal } from './useModal';

type TProps = {
  warningProps?: WarningDialogProps;
};

const useWarningOnExit = ({
  warningProps = {},
}: TProps): {
  setUnsavedForm: Dispatch<SetStateAction<boolean>>;
  unsavedForm: boolean;
} => {
  const router = useRouter();
  const [unsavedForm, setUnsavedForm] = useState(false);
  const { openModal } = useModal();

  useEffect(() => {
    const beforeUnloadHandler = () => {
      return undefined;
    };

    const beforeRouteHandler = (url: string) => {
      if (router.pathname !== url) {
        openModal(
          <WarningDialog
            {...(warningProps || {})}
            // @ts-ignore
            acceptFn={() => {
              if (warningProps.acceptFn) warningProps.acceptFn();

              window.removeEventListener('beforeunload', beforeUnloadHandler);
              router.events.off('routeChangeStart', beforeRouteHandler);

              router.push(warningProps.redirectUrl || url);
            }}
            declineFn={() => {
              if (warningProps.declineFn) {
                warningProps.declineFn();

                if (warningProps.preventDeclineFnDefault) {
                  return;
                }
              }

              router.push(router.pathname);
            }}
          />
        );

        router.events.emit('routeChangeError');
        throw `Route change to "${url}" was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.`;
      }
    };

    if (unsavedForm) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
      router.events.on('routeChangeStart', beforeRouteHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      router.events.off('routeChangeStart', beforeRouteHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      router.events.off('routeChangeStart', beforeRouteHandler);
    };
  }, [unsavedForm]);

  return { setUnsavedForm, unsavedForm };
};

export default useWarningOnExit;
