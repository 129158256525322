export const PAYMENT_TYPES = {
  FEDEX: {
    text: 'FedEx',
    value: 'fedex',
  },
  MAIL: {
    text: 'Mail/USPS',
    value: 'mail',
  },
  ACH: {
    text: 'ACH',
    value: 'ach',
  },
  WIRE: {
    text: 'Wire',
    value: 'wire',
  },
};

export default {};
