import styled from 'styled-components';

import { animations } from '@hl-portals/helpers';

type WithAnimationProps = {
  animation: keyof typeof animations;
  delay?: number;
  duration?: number;
};

const defaults = {
  delay: 0,
  duration: 0.2,
};

const withAnimation = (Component: any, opts: WithAnimationProps) => {
  const opt = { ...defaults, ...opts };

  const ComponentWithAnimation = styled(Component)`
    animation: ${opt.animation} ${opt.duration}s ease-out forwards;
    animation-delay: ${opt.delay || 0}s;
  `;

  return (props: any) => <ComponentWithAnimation {...props} />;
};

export default withAnimation;
