import React, { useEffect } from 'react';

import { ModalStatus } from '../modal.context';
import { ModalBackdrop } from '../styles';

type BackdropComponentProps = {
  className: string;
  currentStatus: ModalStatus;
  onAnimationEnd?: () => void;
  closeModalHandler: () => void;
  enableBackDropClick?: boolean;
  enableCloseOnEsc?: boolean;
  enableClose?: boolean;
};

const BackdropComponent = ({
  className,
  closeModalHandler,
  onAnimationEnd,
  children,
  enableBackDropClick,
  enableCloseOnEsc,
  enableClose,
  currentStatus,
}: React.PropsWithChildren<BackdropComponentProps>) => {
  useEffect(() => {
    const downHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModalHandler();
      }
    };

    if (enableCloseOnEsc && enableClose) {
      window.addEventListener('keydown', downHandler);
    }
    document.body.style.overflowY = 'hidden';

    return () => {
      window.removeEventListener('keydown', downHandler);
      document.body.style.overflowY = '';
    };
    // eslint-disable-next-line
  }, [enableCloseOnEsc]);

  return (
    <ModalBackdrop
      currentStatus={currentStatus}
      className={className}
      onAnimationEnd={onAnimationEnd}
      onClick={() => enableBackDropClick && enableClose && closeModalHandler()}
    >
      {children}
    </ModalBackdrop>
  );
};

export default BackdropComponent;
