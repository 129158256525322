import { uniqueBy } from '@hl-portals/helpers';

import { PropertySuggestion } from '@hl-portals/hooks';

export type AutocompleteOption = {
  display_address: string;
  client_name?: string;
  disabled?: boolean;
  type: string;
  lead?: Partial<Lead>;
  id?: string;
};

export const compileOptions = (
  orders: OrderJsonaResponse[] = [],
  properties: PropertySuggestion[] = []
): AutocompleteOption[] => {
  const orderOptions = [] as AutocompleteOption[];

  orders.forEach((order) => {
    (order.leads || []).forEach((lead) => {
      orderOptions.push({
        type: 'client',
        id: lead.id,
        lead: {
          ...lead,
          provider_leads: [], // remove this to make change comparison easier
          relationshipNames: [], // remove this to make change comparison easier
        },
        client_name: order.client_name,
        display_address:
          `${lead.address}, ${lead.city_display}, ${lead.state_code}, ${lead.zip_code}`.trim(),
      });
    });
  });

  const propertyOptions = properties.map((property) => ({
    type: 'property',
    id: property.id,
    display_address: property.attributes.display_address,
  }));

  return [
    ...uniqueBy(orderOptions, 'id'),
    ...uniqueBy(propertyOptions, 'id'),
  ].filter(Boolean);
};

export const getSharedPrefix = (address: string, term: string) => {
  let lastSharedChar = 0;
  while (
    lastSharedChar < address.length &&
    address.charAt(lastSharedChar).toLowerCase() ===
      term.charAt(lastSharedChar).toLowerCase()
  )
    lastSharedChar++;

  return address.substring(0, lastSharedChar);
};

const productMap = {
  cc_trade_in: 'Buy Before You Sell',
  cc_cash_offer: 'Cash Offer',
  cc_hl_simple_sale: 'Simple Sale',
  escrow: 'Closing Services',
  buyer: 'Buyer',
  seller: 'Seller',
};

export const getProduct = (lead?: Partial<Lead>): string | undefined => {
  if (!lead || !lead.user_type) return;

  return productMap[lead.user_type];
};

export const getSuggestedAddress = (address = '', term = '') => {
  if (!term) return address;
  if (!address) return <></>;

  const prefix = getSharedPrefix(address, term);

  return (
    <>
      <span>{prefix}</span>
      <strong>{address.slice(prefix.length)}</strong>
    </>
  );
};
