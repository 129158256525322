import { useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { CLIENT_TASKS_PATH } from '@hl-portals/constants';

const useTransactionTasks = ({
  leadIds = [],
  userId,
}: {
  leadIds: Array<string | string[]>;
  userId: string | number;
}) =>
  useQuery({
    queryKey: `CLIENT_TASKS_PATH_${leadIds.join(',')}`,
    queryFn: () =>
      http.private({
        url: CLIENT_TASKS_PATH({ leadIds, userId }),
        translateJsona: true,
      }),
    enabled: !!leadIds.length && !!userId,
  });

export default useTransactionTasks;
