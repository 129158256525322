import axios from 'axios';

import { getCsrfToken, signOut as nextSignOut, signIn } from 'next-auth/react';

import { utils as authUtils } from '@hl-portals/libs/auth';

import { LOGOUT_PATH } from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

interface SignOutProps {
  callbackUrl?: string;
  logoutParams?: {
    user: {
      impersonated: boolean;
    };
  };
}

export const signOut = async (options?: SignOutProps) => {
  try {
    // resolve callbackUrl
    const defaultCallbackUrl = authUtils.getSigninCallbackUrl(window?.location);

    const { callbackUrl = defaultCallbackUrl, logoutParams = {} } =
      options || {};

    // log out of hapi
    await axios({ url: LOGOUT_PATH, method: 'POST', data: logoutParams });

    // sign out of next auth without redirecting
    await nextSignOut({ redirect: false });

    localStorage.removeItem('hubspotVisitorToken');

    // start sign in process as defined by nextauth
    await signIn('homelight', {
      callbackUrl,
    });
  } catch (err) {
    console.error(err);
  }
};

export const signOutV2 = async (options?: SignOutProps) => {
  try {
    const { logoutParams = {} } = options || {};

    // log out of hapi
    await axios({ url: LOGOUT_PATH, method: 'POST', data: logoutParams });

    // sign out of next auth without redirecting
    await nextSignOut({ redirect: false });

    localStorage.removeItem('hubspotVisitorToken');

    // https://github.com/nextauthjs/next-auth/blob/next-auth%40v4.3.4/packages/next-auth/src/core/lib/csrf-token.ts#L25
    const csrfToken = await getCsrfToken();
    window.location.href = `${config.homelightHost}/auth/logout?client_id=${config.clientId}&state=${csrfToken}`;
  } catch (err) {
    console.error(err);
  }
};
