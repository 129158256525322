/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';

import { GENERIC_USER_CURRENT_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

export const useCurrentUser = () =>
  useQuery({
    queryKey: GENERIC_USER_CURRENT_PATH,
    queryFn: () =>
      enhancedAxios({
        url: GENERIC_USER_CURRENT_PATH,
        translateJsona: true,
      }),
    enabled: true,
  });

export default useCurrentUser;
