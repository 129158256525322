/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';

import { TRANSACTION_FILES_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

interface TransactionDocumentResponse {
  data: Array<TransactionDocument>;
  links: {
    first: string;
    last: string;
  };
  meta: {
    total_transactions: number;
  };
}

export const useTransactionDocuments = ({
  leadIds = [],
}: {
  leadIds: Array<number | string>;
}) =>
  useQuery({
    queryKey: `TRANSACTION_FILES_PATH_${leadIds.join(',')}`,
    queryFn: () =>
      enhancedAxios<TransactionDocumentResponse>({
        url: TRANSACTION_FILES_PATH({ leadIds }),
        translateJsona: true,
        params: {},
        bypassAuth: true,
      }),
    enabled: Boolean(leadIds.length),
  });
