/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosError } from 'axios';

import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';

import { useModal } from './useModal';

export type GenericResult = {
  data: any;
  error?: AxiosError;
} & UseQueryResult;

export interface MfaErrorResponseData {
  auth_complete: boolean;
  flow: 'registration' | 'verification';
  scope: 'controlled' | 'restricted';
  authentication_factors?: {
    data: Array<{
      type: 'authentication_factor';
      id: string;
      attributes: {
        name: string;
      };
    }>;
  };
  authentication_devices?: {
    data: Array<{
      type: 'authentication_factor';
      id: string;
      attributes: {
        name: string;
      };
    }>;
  };
}

type UseSecureRequestProps = {
  reactQuery: GenericResult;
  OnFailModal: React.ComponentType<{
    onDone: () => void;
    data: MfaErrorResponseData;
  }>;
};

const mfaErrorMessages = [
  "Scope 'controlled' is required to access this resource",
  "Scope 'restricted' is required to access this resource",
];

export const useSecureRequest = ({
  reactQuery,
  OnFailModal,
}: UseSecureRequestProps) => {
  const { openModal, closeModal, isModalOpened } = useModal();

  const onMfaComplete = () => {
    reactQuery.refetch();
    closeModal({ clearAll: true });
  };

  useEffect(() => {
    const { isLoading, isError, error } = reactQuery;

    if (isLoading || !isError) return;

    if (
      error instanceof Error &&
      mfaErrorMessages.includes(error.response?.data?.message) &&
      !isModalOpened
    ) {
      openModal(
        <OnFailModal
          onDone={onMfaComplete}
          data={error.response?.data?.data}
        />,
        {
          wrapperClassName: 'secureRequest-modal__wrapper',
          ignoreStack: true,
        }
      );
    }
  }, [reactQuery.error]);

  return reactQuery;
};

export default useSecureRequest;
