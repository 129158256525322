import Image from 'next/image';

import { theme } from '@hl-portals/constants';

import { useMapboxStaticMap } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import { StaticMapImg } from './styles';
import { MapboxStaticMapProps } from './types';

type StaticMapProps = {
  address: string;
  mapProps?: MapboxStaticMapProps;
  marker?: string;
  searchType?: string;
} & BoxTypes;

export const StaticMap = ({
  address,
  marker = 'house',
  searchType = 'address',
  mapProps,
  ...otherProps
}: StaticMapProps): React.ReactElement => {
  const staticMapSrc = useMapboxStaticMap({ address, searchType, ...mapProps });
  const markerImage = marker === 'homelight' ? '/homelight-marker.png' : '/home-marker-circle.png';
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      backgroundColor={theme.colors.coolGray5}
      borderRadius="8px"
      {...otherProps}
    >
      {staticMapSrc ? (
        <Box
          position="relative"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <StaticMapImg
            src={staticMapSrc}
            alt={`map image of ${address}`}
            width="100%"
          />

          <Box
            position="absolute"
            width={{ xs: '32px', md: '48px' }}
            height={{ xs: '32px', md: '48px' }}
          >
            <Image src={markerImage} layout="fill" />
          </Box>
        </Box>
      ) : (
        <Icon type="home" size={50} fill="darkBlue" />
      )}
    </Box>
  );
};

export default StaticMap;
