import { ReactNode } from 'react';

import { Box } from '../Box';
import NavbarDropdown from '../NavbarDropdown';
import { IDropdownUser, INavarDropdownSection } from '../NavbarDropdown/types';

export type NavbarItemProps = {
  key: string;
  display?: ReactNode;
  sections?: INavarDropdownSection[];
  onClick?: () => void;
  user?: IDropdownUser;
};

export const NavbarItem = ({
  sections = [],
  user,
}: NavbarItemProps & { user: IDropdownUser }): React.ReactElement => {
  return (
    <Box position="relative" alignItems="center">
      <NavbarDropdown isElite={false} sections={sections} user={user} />
    </Box>
  );
};

export default NavbarItem;
