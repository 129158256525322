import { FlexboxProps, SizingProps, SpacingProps } from '@mui/system';

import { ReactNode } from 'react';

import { Box } from '../Box';
import { IDropdownUser } from '../NavbarDropdown/types';
import { NavbarItem, NavbarItemProps } from './NavbarItem';
import { Logo, NavbarWrapper } from './styles';

type NavbarProps = {
  children?: ReactNode;
  items: NavbarItemProps[];
  logoUrl?: string;
  user: IDropdownUser;
} & FlexboxProps &
  SpacingProps &
  SizingProps;

export const Navbar = ({
  children,
  logoUrl = '',
  items = [],
  user,
  ...rest
}: NavbarProps): React.ReactElement => (
  <NavbarWrapper {...rest}>
    <Box
      margin="0 auto"
      maxWidth="1512px"
      width="100%"
      justifyContent="space-between"
    >
      {logoUrl && (
        <Logo
          src={logoUrl}
          alt="HomeLight logo"
          data-test="header-logo"
          onClick={() =>
            (window.location.href = process.env['HOMELIGHT_HOST'] || '')
          }
        />
      )}
      {items.map(({ key, ...props }) => (
        <NavbarItem user={user} {...props} key={key} />
      ))}
      {children}
    </Box>
  </NavbarWrapper>
);

export default Navbar;
