import { useEffect, useState } from 'react';

import { useUserEvents } from '@hl-portals/hooks';

import { BoxTypes } from '../Box';
import ProgressGroup from './ProgressGroup';
import { findActiveIndex } from './helpers';

export type ProgressTrackerProps = {
  leadId: string;
  groups: TransactionProgressParentStep[];
} & BoxTypes;

const ProgressTracker = ({
  leadId,
  groups,
}: ProgressTrackerProps): React.ReactElement => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { recordUserEvent } = useUserEvents();

  useEffect(() => {
    setActiveIndex(findActiveIndex(groups));
  }, [groups]);

  const onToggle = (idx: number) => () => {
    recordUserEvent({
      source: 'client_portal',
      event_action: 'progress_tracker_clicked',
      result: {
        lead_id: leadId as unknown as number,
      },
    });

    setActiveIndex(idx);
  };

  return (
    <>
      {groups.map((group, i) => (
        <ProgressGroup
          key={group.title}
          num={i + 1}
          group={group}
          onToggle={onToggle(i)}
          active={activeIndex === i}
        />
      ))}
    </>
  );
};

export default ProgressTracker;
