import { useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { CLIENT_TRANSACTION_PATH } from '@hl-portals/constants';

import { GenericResult, useSecureRequest } from '@hl-portals/hooks';

import StepUpAuth from '../components/StepUpAuth';

export const useTransaction = ({
  orderId,
  side,
  enabled = true,
}: {
  orderId: string | string[];
  side: 'buyer' | 'seller';
  enabled?: boolean;
}) =>
  useSecureRequest({
    reactQuery: useQuery({
      queryKey: `CLIENT_TRANSACTION_PATH_${orderId}_${side}`,
      queryFn: () =>
        http.private({
          translateJsona: true,
          url: CLIENT_TRANSACTION_PATH({ orderId, side }),
          params: {
            id: orderId,
            side,
          },
        }),
      enabled: Boolean(orderId && side) && enabled,
    }) as GenericResult,
    OnFailModal: StepUpAuth,
  });

export default useTransaction;
