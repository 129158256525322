import React from 'react';

import { Avatar, Box, Icon, Paragraph } from '@hl-portals/ui';

import { NavbarHeaderWrapper, NavbarProfile } from './styles';
import { INavbarDropdownHeaderProps } from './types';

const NavbarDropdownHeader = ({
  user,
  onClose,
  isDesktop,
}: INavbarDropdownHeaderProps): React.ReactElement => {
  return (
    <NavbarHeaderWrapper>
      {!isDesktop && (
        <Box display="flex" justifyContent="flex-end">
          <Icon type="close" fill="darkBlue" onClick={() => onClose(false)} />
        </Box>
      )}
      <NavbarProfile>
        <Avatar
          type="normal"
          initials={user?.firstName?.[0]}
          mr="10px"
          surface="blue"
        />
        <Box
          flexDirection="column"
          data-test="navbar-profile-user-name"
          overflowY="hidden"
        >
          <Paragraph
            variant="heading-5"
            color="darkBlue"
            mb="2px"
            truncate
            maxWidth="200px"
            title={`${user?.firstName} ${user?.lastName}`}
          >
            {user?.firstName} {user?.lastName}
          </Paragraph>
          <Paragraph
            variant="text-small"
            color="coolGray2"
            truncate
            maxWidth="200px"
            title={user?.email}
          >
            {user?.email}
          </Paragraph>
        </Box>
      </NavbarProfile>
    </NavbarHeaderWrapper>
  );
};

export default NavbarDropdownHeader;
