import { useQuery } from 'react-query';

import { MESSAGES_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

interface UseMessageDetailsParams {
  messageId: string;
}

export interface UseMessageDetailsResponse {
  data: Array<MessageType>;
  meta: {
    total_messages: number;
  };
}

export const useMessageDetails = ({ messageId }: UseMessageDetailsParams) =>
  useQuery({
    queryKey: `MESSAGES_PATH_${messageId}`,
    queryFn: () =>
      enhancedAxios<UseMessageDetailsResponse>({
        url: MESSAGES_PATH({ messageId }),
        translateJsona: true,
      }),
    enabled: Boolean(messageId),
  });
